<template>
  <div style="height: 450px">
    <div class="mb-2">
      <v-select
        v-model="selectedPlacesIds"
        :filterable="false"
        :options="options"
        :placeholder="placeholder"
        label="description"
        multiple
        class="select-multiple"
        @search="fetchOptions"
        @option:selected="handlePlaceSelected"
        @option:deselected="handlePlaceDeselected"
      />
    </div>
    <places-map ref="map" :locations="locations" :is-editable="true" />
  </div>
</template>

<script>
import { Loader } from '@googlemaps/js-api-loader';
import vSelect from 'vue-select';
import PlacesMap from '@/@core/components/places-map-input/PlacesMap.vue';

export default {
  name: 'PlacesMapSelect',
  components: { vSelect, PlacesMap },
  model: {
    prop: 'locations',
    event: 'update:locations',
  },
  props: {
    locations: {
      type: Array, // Location[]
      default: () => [],
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      google: null,
      autocomplete: null,
      placesService: null,

      bounds: null,

      selectedPlacesIds: [],
      options: [],
    };
  },
  watch: {
    locations() {
      this.selectedPlacesIds = [];
      this.locations.forEach((location) => {
        this.selectedPlacesIds.push({
          code: `dummy-id-${location.key}`, // use dummy id to avoid fetching the placeId from geocoding.
          description: location.name || location.address,
        });
      });
    },
  },
  async mounted() {
    const loader = new Loader({
      apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
      version: 'weekly',
      libraries: ['places'],
    });
    this.google = await loader.load();

    this.autocompleteService = new this.google.maps.places.AutocompleteService();
    this.placesService = new this.google.maps.places.PlacesService(this.$refs.map.getMap());

    // Bias the autocomplete results towards current map's viewport.
    this.$refs.map.getMap().addListener('bounds_changed', () => {
      this.bounds = this.$refs.map.getMap().getBounds();
    });

    if (this.locations) {
      this.locations.forEach((location) => {
        this.selectedPlacesIds.push({
          code: `dummy-id-${location.key}`, // use dummy id to avoid fetching the placeId from geocoding.
          description: location.name || location.address,
        });
      });
    }
  },
  methods: {
    async fetchOptions(search, loading) {
      if (!search) {
        this.options = [];
        return;
      }

      loading(true);
      const promise = new Promise((resolve) => {
        this.autocompleteService.getPlacePredictions(
          {
            input: search,
            bounds: this.bounds,
          },
          (predictions, status) => {
            if (status !== this.google.maps.places.PlacesServiceStatus.OK) {
              resolve([]);
              return;
            }

            resolve(predictions);
          },
        );
      });

      loading(false);
      const predictions = await promise;

      this.options = predictions
        .map(({ place_id: code, description }) => ({ code, description }));
    },
    handlePlaceSelected(selectedOptions) {
      const option = selectedOptions[selectedOptions.length - 1];
      if (!option) {
        return;
      }

      this.placesService.getDetails({ placeId: option.code }, (placeResult, status) => {
        if (status === this.google.maps.GeocoderStatus.OK) {
          const postalCode = placeResult?.address_components
            .find(({ types }) => types.includes('postal_code'))?.long_name;
          const locality = placeResult?.address_components.find(({ types }) => types.includes('locality'))?.long_name;
          const region = placeResult?.address_components
            .find(({ types }) => types.includes('administrative_area_level_1'))?.long_name;
          const location = {
            name: option?.description,
            latitude: placeResult?.geometry?.location?.lat(),
            longitude: placeResult?.geometry?.location?.lng(),
            postalCode,
            locality,
            region,
          };
          this.$emit('update:locations', [...this.locations, location]);
        }
      });
    },
    handlePlaceDeselected(deselectedOption) {
      let index = 0;

      for (const row in this.locations) {
        if (this.locations[row].name != null || this.locations[row].address != null) {
          index = this.locations[row].name === deselectedOption.description || this.locations[row].address === deselectedOption.description ? row : index;
        }
      }

      this.locations.splice(index, 1);

      this.$emit('update:locations', this.locations);
    },
  },
};
</script>

<style lang="scss" scoped>
  .map {
    height: 100%;
  }
</style>

<style>
  .pac-container {
    z-index: 1051 !important;
  }
</style>
